import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Button, Image, Form } from 'semantic-ui-react';
import { Wrapper } from './styles';
import { ASIA_PACIFIC_URL, UNITED_KINGDOM_EUROPE_URL, UNITED_STATES_URL } from '../App/constants';

const REGIONS = {
  ASIA_PACIFIC: 'ASIA_PACIFIC',
  UNITED_KINGDOM_EUROPE : 'UNITED_KINGDOM_EUROPE',
  UNITED_STATES: 'UNITED_STATES'
}

const REGION2URL = {
  [REGIONS.ASIA_PACIFIC]: ASIA_PACIFIC_URL,
  [REGIONS.UNITED_KINGDOM_EUROPE]: UNITED_KINGDOM_EUROPE_URL,
  [REGIONS.UNITED_STATES]: UNITED_STATES_URL
}
class Regions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      disabled: false,
    };

  }

  getDateYear() {
    const d = new Date()
    return d.getFullYear()
  }

  handleRegionSelectionClick(region) {
    console.log('handleRegionSelectionClick ' + region)
    window.location = REGION2URL[region]
  }

  renderComputerTablet() {
      return (
          <Grid.Column style={{ paddingLeft: '100px', paddingRight: '100px' }}>
            <img className="logo" src="/assets/till-logo-black.png" alt="Till Payments Logo" />
            <React.Fragment>
              <Form className="login" size="large" style={{ padding: '25px'}}>
                  <p style={{ marginTop: 8, marginBottom: 20, fontFamily: '"Roboto, Arial",sans-serif', fontSize: 16, fontWeight: 300 }}>Please select your region</p>
                      <Grid verticalAlign='middle' centered style={{marginTop:'auto' }}>
                          <Grid.Row>

                              <Grid.Column width={5}>
                                  <Button
                                      style={{ fontFamily: "'Roboto', Tahoma, Arial", background: '#f9581f', color: 'white', fontWeight: '400', fontSize: 16, height: '80px' }}
                                      fluid
                                      size="large"
                                      onClick={() => this.handleRegionSelectionClick(REGIONS.ASIA_PACIFIC)}
                                  >Asia Pacific</Button>
                              </Grid.Column>
                              <Grid.Column width={5}>
                                  <Button
                                      style={{ fontFamily: "'Roboto', Tahoma, Arial", background: '#f9581f', color: 'white', fontWeight: '400', fontSize: 16, height: '80px' }}
                                      fluid
                                      size="large"
                                      onClick={() => this.handleRegionSelectionClick(REGIONS.UNITED_KINGDOM_EUROPE)}>United Kingdom / Europe</Button>
                              </Grid.Column>
                              <Grid.Column width={5}>
                                  <Button
                                      style={{ fontFamily: "'Roboto', Tahoma, Arial", background: '#f9581f', color: 'white', fontWeight: '400', fontSize: 16, height: '80px' }}
                                      fluid
                                      size="large"
                                      onClick={() => this.handleRegionSelectionClick(REGIONS.UNITED_STATES)}>United States</Button>
                              </Grid.Column>
                        </Grid.Row>
                      </Grid>
                </Form>
                </React.Fragment>
            </Grid.Column>
      )
  }

    renderMobile() {
        return (
            <Grid.Column style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                <img className="logo" src="/assets/till-logo-black.png" alt="Till Payments Logo" />
                <React.Fragment>
                    <Form className="login" size="large" style={{ padding: '25px'}}>
                        <p style={{ marginTop: 8, marginBottom: 20, fontFamily: '"Roboto, Arial",sans-serif', fontSize: 16, fontWeight: 300 }}>Please select your region</p>
                        <Grid verticalAlign='middle' centered style={{marginTop:'auto' }}>
                            <Grid.Row>
                                    <Button
                                        style={{ fontFamily: "'Roboto', Tahoma, Arial", background: '#f9581f', color: 'white', fontWeight: '400', fontSize: 16, height: '80px' }}
                                        fluid
                                        size="large"
                                        onClick={() => this.handleRegionSelectionClick(REGIONS.ASIA_PACIFIC)}
                                    >Asia Pacific</Button>
                            </Grid.Row>
                            <Grid.Row>
                                    <Button
                                        style={{ fontFamily: "'Roboto', Tahoma, Arial", background: '#f9581f', color: 'white', fontWeight: '400', fontSize: 16, height: '80px' }}
                                        fluid
                                        size="large"
                                        onClick={() => this.handleRegionSelectionClick(REGIONS.UNITED_KINGDOM_EUROPE)}>United Kingdom / Europe</Button>

                            </Grid.Row>
                            <Grid.Row>

                                    <Button
                                        style={{ fontFamily: "'Roboto', Tahoma, Arial", background: '#f9581f', color: 'white', fontWeight: '400', fontSize: 16, height: '80px' }}
                                        fluid
                                        size="large"
                                        onClick={() => this.handleRegionSelectionClick(REGIONS.UNITED_STATES)}>United States</Button>

                            </Grid.Row>
                        </Grid>
                    </Form>
                 </React.Fragment>
            </Grid.Column>
        )
    }

  
  render() {
    return (
          <Wrapper style={{ flexDirection: 'column'}}>
            <Grid>
                <Grid.Row only='computer tablet'>
                  <Grid textAlign="center" style={{ marginTop: '173px', marginBottom: '30px' }}>
                    <Grid.Row >
                        {this.renderComputerTablet()}
                    </Grid.Row>
                  </Grid>
                </Grid.Row>
                <Grid.Row only='mobile'>
                  <Grid textAlign="center" style={{ marginTop: 'auto', marginBottom: '30px' }}>
                      <Grid.Row >
                          {this.renderMobile()}
                      </Grid.Row>
                          </Grid>
                </Grid.Row>
              </Grid>
              <p style={{ marginTop: 'auto', marginBottom: '20px', fontFamily: '"Roboto, Arial",sans-serif', fontSize: 16, fontWeight: 300 }}>© 2019-{this.getDateYear()} Till Payments</p>
          </Wrapper>
    );
  }
}

Regions.propTypes = {
  history: PropTypes.any,
};

const mapStateFromProps = state => ({
});

export default withRouter(connect(mapStateFromProps)(Regions));
