import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import { Provider, connect } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom';
import { createStore } from 'redux';
import rootReducer from './reducers';

import Regions from './containers/Regions/index';

const store = createStore(rootReducer, composeWithDevTools());

class Root extends Component {
  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route path="/" component={Regions} />
          </Switch>
        </div>
      </Router>
    );
  }
}

const mapStateFromProps = state => ({
});

const RootWithAuth = withRouter(connect(mapStateFromProps, { })(Root));

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <RootWithAuth />
    </Router>
  </Provider>, document.getElementById('root'),
);
